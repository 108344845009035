<template lang="pug">
    v-form
        v-row
            v-col(md="2" sm="6" cols="12")
                DatePicker(v-model="dateStart" label="Дата начала")
            v-col(md="2" sm="6" cols="12")
                DatePicker(v-model="dateEnd" label="Дата окончания")
            v-col(md="2" cols="12")
                v-select(v-model="project" item-text="name" item-value="id" :items="projects" label="Проект")
            v-col(md="2" cols="12")
                v-autocomplete(v-model="network" item-text="name" item-value="id" :items="networks" label="Ритейлер" multiple chips)
            v-col(md="2" cols="12")
                v-autocomplete(v-model="city" :items="cities" label="Город" multiple chips)
            v-col(md="2" cols="12")
                v-autocomplete(v-model="user" :items="users" label="Пользователи" chips item-text="name" item-value="id")
        v-row
            v-col
                v-btn(:disabled="!valid" @click="loadReport") Получить отчёт
        v-row
            v-col
                v-data-table(class="elevation-1 search-table" :sort-by.sync="sortBy" :footer-props="footer_props" :height="tableHeight" :options.sync="options" :server-items-length="totalDesserts" :headers="headers" :items="reports" fixed-header :loading="loading" loading-text="Загружается... Пожалуйста подождите")
                    template(v-slot:item="{ item }")
                        tr
                            td(v-for="(header, key) in headers" :key="key")
                                v-btn(:to="`/report/create/${item.id}/project/${project}`" v-if="!isCustomer && header.value == 'address'" class="float-right" x-small elevation="2" fab title="Добавить отчёт")
                                    v-icon mdi-plus
                                span(v-if="header.value == 'address'" v-html="item[header.value]")
                                span(v-else-if="header.value == 'tradePointExt'" v-html="item.extId")
                                div(v-else-if="item[header.value] != null")
                                    router-link(:to="'/report/' + item[header.value].id" target='_blank') отчёт
                                    div Фото: {{ item[header.value].fotos }}

</template>


<script>
    import { projectService, networkService, tradepointService, reportService } from '@/_services'
    import { mapGetters, mapMutations, mapState } from "vuex";
    import DatePicker from './DatePicker';

    export default {
        components: {
            DatePicker
        },
        data: () => ({
            dateStart: new Date().toISOString().substr(0, 10),
            dateEnd: new Date().toISOString().substr(0, 10),
            modal: false,
            project: 0,
            network: [],
            city: [],
            user: 0,
            sortBy: 'address',
            headers: [],
            reports: [],
            cities: [],
            cities_rules: [v => v.length > 0 || 'Необходимо выбрать город'],
            net_rules: [v => v.length > 0 || 'Необходимо выбрать ритейлера'],
            loading: false,
            options: {},
            totalDesserts: 10,
            footer_props: {
                'items-per-page-options': [25, 50, 100]
            }
        }),
        mounted() {
            this.loadProjects()
        },
        computed: {
            ...mapState("project", ["projects"]),
            ...mapGetters("authentication", ["checkRole"]),
            ...mapState("network", { allnetworks: "networks" }),
            ...mapGetters("user", ["users"]),
            // ...mapState("tradepoint",["cities"]),
            isCustomer() {
                return this.checkRole(0)
            },
            networks() {
                return this.allnetworks[this.project]
            },
            valid() {
                return this.project != 0
            },
            tableHeight() {
                return this.reports.length > 7 ? '500' : 'auto'
            }
        },
        watch: {
            project: function(val) {
                this.loadNetworks(val)
                this.loadCities()
            },
            network: function(val) {
                this.loadCities()
            },
            options: {
                handler() {
                    this.loadReport();
                },
                deep: true,
            },
        },
        methods: {
            ...mapMutations("project", ["setProjects"]),
            ...mapMutations("network", ["setNetworkByProject"]),
            // ...mapMutations("tradepoint", ["setCities"]),
            loadProjects() {
                if (this.projects.length > 0) return
                projectService.list().then(data => {
                    this.setProjects(data)
                    if (data.length > 0)
                        this.project = data[0].id
                })
            },
            loadNetworks(id) {
                if (this.networks && this.networks.length > 0) return
                networkService.loadMyNetwork(id).then(data => {
                    this.setNetworkByProject({ id: id, networks: data })
                })
            },
            loadCities() {
                tradepointService.loadCities(this.project, this.network.join()).then(data => this.cities = data)
            },
            loadReport() {
                if (this.project === 0) return;
                this.loading = true
                let sort = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
                let sortDesc = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false;
                let params = { start: this.dateStart, end: this.dateEnd, page: this.options.page - 1, size: this.options.itemsPerPage, sort, sortDesc };
                if (this.network.length > 0)
                    params['networks'] = this.network.join()
                if (this.city.length > 0)
                    params['cities'] = this.city.join()
                if (this.user > 0)
                    params['user'] = this.user
                reportService.list(this.project, params).then(result => {
                    this.totalDesserts = result.total
                    this.headers = [{ text: 'ID', value: 'tradePointExt', fixed: true, width: '100px' }, { text: 'Адрес', value: 'address', fixed: true, width: '200px' }]
                    for(let start = new Date(Date.parse(this.dateStart)), end = new Date(Date.parse(this.dateEnd)); start <= end; start.setDate(start.getDate() + 1)) {
                        let field = this.dateFormat(start)
                        this.headers.push({ text: field, value: field, sortable: false })
                    }
                    this.reports = []
                    let data = result.data;
                    for(let i = 0;i < data.length;i++) {
                        let obj = { id: data[i].tradePointId, extId: data[i].tradePointExt, address: `<b>${this.getNameNetwork(data[i].networkId)}</b>, ` + data[i].address }
                        for(let j = 0;j < data[i].reports.length;j++) {
                            let d = new Date(Date.parse(data[i].reports[j].date))
                            let field = this.dateFormat(d)
                            obj[field] = data[i].reports[j]
                        }
                        this.reports.push(obj)
                    }
                    // this.reports = this.reports.sort((a, b) => a.address > b.address ? 1 : a.address < b.address ? -1 : 0)
                    this.loading = false
                })
            },
            dateFormat(date) {
                return `${date.getDate() < 10? '0' : ''}${date.getDate()}.${date.getMonth() + 1 < 10? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()}`
            },
            getNameNetwork(id) {
                return this.networks.find(n => n.id === id).name
            }
        }
    }
</script>

<style>
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1),
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0;
        background: white;
        border-right: 1px solid #000;
        font-size: 13px;
        z-index: 3;
    }
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        left: 100px;
    }
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2n + 3),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2n + 3) {
        background: #f9f9f9
    }
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        z-index: 4;
    }
    .search-table > .v-data-table__wrapper > table > tbody > tr:hover > td {
        background: #ececec
    }
    a:visited {
        color: #609;
    }
</style>